<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.packaging_code") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        class="table-padding-2"
        fixed-header
        height="calc(100vh - 245px)"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center" style="width: 150px">
                <InputFilter
                  :label="$t('labels.packaging_code')"
                  :placeholder="$t('labels.packaging_code')"
                  name="code"
                  @onFilter="onFilterChange"
                />
              </th>
              <th role="columnheader" class="text-center" style="width: 150px">
                <SelectFilter
                  :options="packagingTypes"
                  :label="$t('labels.type')"
                  :placeholder="$t('labels.type')"
                  name="type"
                  sort-name="type"
                  has-sort
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" class="text-center">
                <InputFilter
                  :label="$t('labels.name')"
                  :placeholder="$t('labels.name')"
                  name="name"
                  @onFilter="onFilterChange"
                />
              </th>
              <th role="columnheader" class="text-center" style="width: 120px">
                <SelectFilter
                  :options="packagingUnits"
                  :label="$t('labels.unit')"
                  :placeholder="$t('labels.unit')"
                  name="unit"
                  @onFilter="onFilterChange"
                />
              </th>
              <th role="columnheader" class="text-center" style="width: 180px">
                <InputFilter
                  :label="$t('labels.size_v')"
                  :placeholder="$t('labels.size_v')"
                  name="dxrxc"
                  @onFilter="onFilterChange"
                />
              </th>
              <th role="columnheader" class="text-center">
                <v-btn
                  color="success"
                  small
                  @click="addConfig"
                  :disabled="isDisabledBtnAdd"
                >
                  {{ $t("labels.add") }}
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="item.id"
              class="text-center"
            >
              <td>
                <span
                  v-if="!item.editing"
                  class="cursor-pointer primary--text text-decoration-underline"
                  @click="showUseDialog(item)"
                >
                  {{ item.code }}
                </span>
                <v-text-field
                  v-else
                  class="c-input-small"
                  v-model="item.code"
                  :placeholder="$t('labels.packaging_code')"
                  dense
                  outlined
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <span v-if="!item.editing">{{ item.type }}</span>
                <v-autocomplete
                  v-else
                  class="c-input-small"
                  v-model="item.type"
                  :items="packagingTypes"
                  :placeholder="$t('labels.type')"
                  dense
                  outlined
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td>
                <span v-if="!item.editing">{{ item.name }}</span>
                <v-text-field
                  v-else
                  class="c-input-small"
                  v-model="item.name"
                  :placeholder="$t('labels.name')"
                  dense
                  outlined
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <span v-if="!item.editing">{{ item.unit }}</span>
                <v-autocomplete
                  v-else
                  class="c-input-small"
                  v-model="item.unit"
                  :items="packagingUnits"
                  :placeholder="$t('labels.unit')"
                  dense
                  outlined
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td>
                <span v-if="!item.editing">{{ item.dxrxc }}</span>
                <v-row no-gutters v-else>
                  <v-col>
                    <v-text-field
                      class="c-input-small"
                      v-model="item.d_size"
                      :placeholder="$t('labels.d_size')"
                      dense
                      outlined
                      hide-details
                      single-line
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      class="c-input-small"
                      v-model="item.r_size"
                      :placeholder="$t('labels.r_size')"
                      dense
                      outlined
                      hide-details
                      single-line
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      class="c-input-small"
                      v-model="item.c_size"
                      :placeholder="$t('labels.c_size')"
                      dense
                      outlined
                      hide-details
                      single-line
                    ></v-text-field>
                  </v-col>
                </v-row>
              </td>
              <td class="py-1">
                <template v-if="!item.editing">
                  <v-btn
                    x-small
                    color="warning"
                    @click="toggleEditing(item, index, true)"
                  >
                    {{ $t("labels.edit") }}
                  </v-btn>
                </template>
                <template v-else>
                  <v-btn
                    class="mb-1"
                    x-small
                    color="success"
                    @click="updateConfig(item)"
                  >
                    {{ $t("labels.save") }}
                  </v-btn>
                  <br />
                  <v-btn
                    x-small
                    color="error"
                    @click="toggleEditing(item, index, false)"
                  >
                    {{ $t("labels.cancel") }}
                  </v-btn>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div class="pt-3">
        <v-pagination
          v-model="page"
          v-if="totalPage > 1"
          :length="totalPage"
          :total-visible="5"
        ></v-pagination>
      </div>
    </v-card-text>

    <v-dialog v-model="useDialog" persistent max-width="820px">
      <WpUseDialog
        v-if="useDialog"
        @cancel="hideUseDialog"
        :packaging="packaging"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS, PACKAGING_TYPES, PACKAGING_UNITS } from "@/libs/const";
import { debounce } from "lodash/function";

export default {
  name: "CreateDialog",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    WpUseDialog: () => import("@/components/packaging/WpUseDialog"),
  },
  props: {},
  data: () => ({
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    licenseTypes: [],
    yesNoOptions: [...YES_NO_OPTIONS],
    packagingTypes: [...PACKAGING_TYPES],
    packagingUnits: [...PACKAGING_UNITS],
    items: [],
    filters: {},
    isLoading: false,
    useDialog: false,
    packaging: {},
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    showUseDialog(item) {
      this.packaging = { ...item };
      this.useDialog = true;
    },
    hideUseDialog() {
      this.packaging = {};
      this.useDialog = false;
    },
    cancel() {
      this.$emit("cancel");
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addConfig() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        code: null,
        created_at: null,
        height: null,
        length: null,
        name: null,
        total: 0,
        type: null,
        unit: null,
        width: null,
        dxrxc: null,
        d_size: null,
        r_size: null,
        c_size: null,
        editing: true,
      });
    },
    async updateConfig(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/packaging/v1/save", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    getList: debounce(function () {
      httpClient
        .post("/packaging/v1/list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows].map((item) => ({
            ...item,
            editing: false,
          }));
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
  },
};
</script>

<style scoped></style>
